import  { useRef, useEffect} from 'react';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Uploads from '../../services/Uploads';
//@ts-ignore
import ImageUploader from "quill-image-uploader";
import "quill-image-uploader/dist/quill.imageUploader.min.css"
import "./RichTextEditor.css"
import { useAuth0 } from '@auth0/auth0-react';

Quill.register("modules/imageUploader", ImageUploader);

interface RichTextEditorProps {
  value: string;
  onChange: (newValue: string) => void;
  onInsertText?: string
}

const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
    
      [{ 'header': 1 }, { 'header': 2 }],               
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
    
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
    
      ['image', 'clean']
    ],
  },
  imageUploader: {
    upload: function (file: any) {
      return new Promise((resolve, reject) => {
        const {getAccessTokenSilently} = useAuth0()
        const formData = new FormData();  
        formData.append('file', file);
        Uploads.uploadImage(getAccessTokenSilently, sessionStorage['clientId'], formData)
          .then(result => {
            resolve(result.image_url);
          })
          .catch(error => {
            reject('Upload failed');
            console.error('Error:', error);
          });
      });
    },
  },
}

export default function RichTextEditor(props: RichTextEditorProps) {
  const quillRef = useRef<ReactQuill | null>(null); 

  const { value, onChange, onInsertText } = props

  useEffect(() => {
    if (onInsertText && quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      quill.insertText(range.index, onInsertText);
      onChange(quill.root.innerHTML);
    }
  }, [onInsertText]);

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'imageBlot',
    'code-block',
    'color',
    'background',
    'strike',
    'script',
    'direction'
  ];


  return (
    <>
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
    />
    </>
  );
}
